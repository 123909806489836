<template>
<div class="fixed inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 sm:rounded-xl">

        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="w-full lg:w-auto inline-block align-bottom bg-white rounded-xl text-center shadow-xl transform transition-all sm:my-8 sm:align-middle">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 rounded-xl">
                <div class="sm:items-start">
                    <div class="mt-3 sm:mt-0">
                        <slot name="header">
                            <div class="text-center">
                                <h3 class="text-3xl leading-6 font-medium text-blue-700">
                                    <faIcon
                                    icon="exclamation-circle"
                                    type="fa"
                                    class="text-blue-700 text-md"
                                    >
                                    </faIcon>
                                    {{ titulo }}
                                </h3>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
            <div class="bg-white pb-4 sm:px-6 sm:pb-4 border-t border-b border-gray-300">
                <div class="sm:items-start">
                    <div class="px-6 pt-6">
                        <slot name="body">
                            <div class="">
                                <p class="my-1 px-8 text-justify text-xl">
                                    {{ parrafo }}
                                </p>
                                <p class="mt-6 text-3xl font-normal">{{ pregunta }}</p>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
            <div class="px-4 py-3 sm:px-6 rounded-xl">
                <slot name="botones">
                    <div class="justify-center">
                        <button 
                        @click="$emit('accion')"
                        :disabled="inhabilitacion"
                        :class="`${colorBotonAccion} rounded-full border border-transparent shadow-md mx-1 px-5 py-3 text-md font-semibold focus:outline-none sm:ml-3 sm:w-auto text-white`">
                            {{ textoBotonAction }}
                        </button>
                        <button 
                        @click="$emit('cerrar')"
                        class="rounded-full border shadow-md mx-1 px-5 py-3 text-md font-semibold focus:outline-none sm:mt-0 sm:w-auto border-transparent bg-gray-600 hover:bg-gray-500 text-white">
                            {{ textoBotonCancel }}
                        </button>
                    </div>
                </slot>   
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        textoBotonCancel: {
            type: String,
            default: 'No'
        },
        textoBotonAction: {
            type: String,
            default: '¡OK!'
        },
        colorBotonAccion: {
            type: String,
            default: 'bg-green-600 hover:bg-green-500'
        },
        titulo: {
            type: String,
            default: 'Título modal'
        },
        parrafo: {
            type: String,
            default: 'Lo que vas hacer ahora puede tener consecuencias desastrosas para la vida de todos los seres vivos de este planeta.'
        },
        pregunta: {
            type: String,
            default: '¿Esta es una pregunta de confirmación?'
        },
        inhabilitacion: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            idRecibido: null
        }
    }
}
</script>