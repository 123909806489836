<template>
  <div>
    <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
        <div class="px-12 py-6">
            <h1 
            class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left">
              <faIcon
              icon="file-alt"
              type="fas"
              class="text-green-500 text-3xl"
              >
              </faIcon>
              Formulario de cotización
            </h1>
            <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Completa los campos para ingresar una cotización o generar una OT.</p>
        </div>
    </div>
    <Form @submit="confirmarCotizacion" :validation-schema="schema" v-slot="{ handleSubmit }" class="bg-white rounded-lg shadow-lg text-gray-900 mt-4">
        <!-- Datos generales -->
        <template v-if="currentStep === 0">
          <div>
            <div class="relative mt-2 mb-2 border-b border-gray-300 px-12 pt-6">
                <h2 class="text-gray-700 font-normal text-2xl 2xl:text-3xl text-left">Datos del cliente</h2>
                <p class="mt-1 text-sm sm:text-md text-gray-500 text-left">Completa los datos del cliente que solicita la cotización.</p> 
            </div>
            <div class="inline-flex sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12 2xl:w-7/12">
              <div class="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mb-3 p-12 sm:text-md xl:text-lg 2xl:text-xl">  
                <div class="relative mt-2 mb-2 text-left">
                    <label for="nombre" class="" > Nombre: </label>
                      <Field
                      id="nombre"
                      name="nombre"
                      class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                      v-model="cotizacion.nombre"
                      />
                      <ErrorMessage name="nombre" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                </div>
                <div class="relative mt-2 mb-2 text-left">
                    <label for="apellidoPaterno" class="" > Apellido paterno: </label>
                      <Field
                      id="apellidoPaterno"
                      name="apellidoPaterno"
                      class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                      v-model="cotizacion.apellidoPaterno"
                      />
                      <ErrorMessage name="apellidoPaterno" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                </div>
                <div class="relative mt-2 mb-2 text-left">
                    <label for="apellidoMaterno" class="" > Apellido materno: </label>
                      <Field
                      id="apellidoMaterno"
                      name="apellidoMaterno"
                      class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                      v-model="cotizacion.apellidoMaterno"
                      />
                      <ErrorMessage name="apellidoMaterno" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                </div>
                <div class="relative mt-2 mb-2 text-left">
                    <label for="telefono" class="" > Teléfono: </label>
                      <Field
                      id="telefono"
                      name="telefono"
                      class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                      v-model="cotizacion.telefono"
                      onkeypress="return /[0-9]/i.test(event.key)"
                      />
                      <ErrorMessage name="telefono" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                </div>
              </div>
            </div>
          </div>  
        </template>

        <!-- Checklist de lentes -->
        <template v-if="currentStep === 1">
          <div class="">
            <div class="relative mt-2 mb-2 border-b border-gray-300 px-12 pt-6">
                <h2 class="text-gray-700 font-normal text-2xl 2xl:text-3xl text-left">Selección de lentes</h2>
                <p class="mt-1 text-sm sm:text-md text-gray-500 text-left">Elige los lentes que el cliente necesita.</p> 
            </div>
            <div class="flex flex-col mb-3 p-6">
              <div class="flex flex-col place-self-center text-left">
                <div class="py-2 flex items-center">
                  <input @change="this.toggleLente(this.lenteLejos, lejosSeleccionado)" id="checkLejos" type="checkbox" class="form-checkbox mr-2 text-5xl border-gray-500" v-model="lejosSeleccionado">
                  <label for="checkLejos" class="text-2xl 2xl:text-3xl pt-1">Lejos</label>
                </div>
                <div class="py-2 flex items-center">
                  <input @change="this.toggleLente(this.lenteCerca, cercaSeleccionado)" id="checkCerca" type="checkbox" class="form-checkbox mr-2 text-5xl border-gray-500" v-model="cercaSeleccionado">
                  <label for="checkCerca" class="text-2xl 2xl:text-3xl pt-1">Cerca</label>
                </div>
                <div class="py-2 flex items-center">
                  <input @change="this.toggleLente(this.lenteMultifocal, multifocalSeleccionado)" id="checkMultifocal" type="checkbox" class="form-checkbox mr-2 text-5xl border-gray-500" v-model="multifocalSeleccionado">
                  <label for="checkMultifocal" class="text-2xl 2xl:text-3xl pt-1">Multifocal</label>
                </div>
                <div class="py-2 flex items-center">
                  <input @change="this.toggleLente(this.lenteBifocal, bifocalSeleccionado)" id="checkBifocal" type="checkbox" class="form-checkbox mr-2 text-5xl border-gray-500" v-model="bifocalSeleccionado">
                  <label for="checkBifocal" class="text-2xl 2xl:text-3xl pt-1">Bifocal</label>
                </div>
              </div>
            </div>
          </div>
        </template>

        <!-- Lente de lejos -->
        <template v-if="currentStep === 2">
          <div class="relative border-b border-gray-300 pb-5 pt-6">
            <h2 class="text-gray-700 font-normal text-2xl 2xl:text-3xl text-center">Lente de lejos</h2>
          </div>
          <div class="inline-flex">
            <div class="grid grid-rows-2 gap-1 mb-3 p-12 sm:text-md 2xl:text-xl">
              <!-- Ojo derecho -->
              <div class="xl:flex">
                <div class="w-full xl:flex xl:w-auto">
                  <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                    <img src="../../assets/img/circulo_celeste_od_64px.png" alt="">
                  </div>
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div>
                    <label for="esfericoDerechoLejos" class="" > Esférico: </label>
                  </div>
                  <div class="relative">
                    <Field
                      id="esfericoDerechoLejos"
                      name="esfericoDerechoLejos"
                      class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                      v-model="lenteLejos.esfericoDerecho"
                      onkeypress="return /[0-9.-]/i.test(event.key)"
                      >
                    </Field>
                    <ErrorMessage name="esfericoDerechoLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>     
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div>
                    <label for="cilindricoDerechoLejos" class="sm:text-md 2xl:text-xl" > Cilíndrico: </label>
                  </div>
                  <div class="relative">
                    <Field
                      id="cilindricoDerechoLejos"
                      name="cilindricoDerechoLejos"
                      class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                      v-model="lenteLejos.cilindricoDerecho"
                      onkeypress="return /[0-9.-]/i.test(event.key)"
                      >
                    </Field>
                    <ErrorMessage name="cilindricoDerechoLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>    
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div>
                    <label for="gradosCilindricoDerechoLejos" class="" > Grados: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="gradosCilindricoDerechoLejos"
                    name="gradosCilindricoDerechoLejos"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteLejos.gradosCilindricoDerecho"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    />
                    <ErrorMessage name="gradosCilindricoDerechoLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right "/>
                  </div>
                </div>
                
              </div>

              <!-- Ojo izquierdo -->
              <div class="xl:flex">
                <div class="w-full xl:flex xl:w-auto">
                  <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                    <img src="../../assets/img/circulo_celeste_oi_64px.png" alt="">
                  </div>
                </div>
                
                <div class="relative text-left px-3 mb-4">
                  <div>
                    <label for="esfericoIzquierdoLejos" class="" > Esférico: </label>
                  </div>
                  <div class="relative">
                    <Field 
                      id="esfericoIzquierdoLejos"
                      name="esfericoIzquierdoLejos"
                      class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                      v-model="lenteLejos.esfericoIzquierdo"
                      onkeypress="return /[0-9.-]/i.test(event.key)"
                      >
                      </Field>
                      <ErrorMessage name="esfericoIzquierdoLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div> 
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div>
                    <label for="cilindricoIzquierdoLejos" class="sm:text-md 2xl:text-xl" > Cilíndrico: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="cilindricoIzquierdoLejos"
                    name="cilindricoIzquierdoLejos"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteLejos.cilindricoIzquierdo"
                    onkeypress="return /[0-9.-]/i.test(event.key)"
                    >
                    </Field>
                    <ErrorMessage name="cilindricoIzquierdoLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div>
                    <label for="gradosCilindricoIzquierdoLejos" class="" > Grados: </label>      
                  </div>
                  <div class="relative">
                    <Field
                    id="gradosCilindricoIzquierdoLejos"
                    name="gradosCilindricoIzquierdoLejos"
                    type="text"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteLejos.gradosCilindricoIzquierdo"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    />
                    <ErrorMessage name="gradosCilindricoIzquierdoLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="border-t border-gray-300 mb-3 px-12 pb-12 text-sm 2xl:text-lg">
            <div class="inline-flex">
              <div class="grid sm:grid-cols-2 grid-cols-1 gap-1 pt-6">

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="tipoFiltroLejos" class="" > Tipo de producto: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="tipoFiltroLejos"
                    name="tipoFiltroLejos"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteLejos.tipoFiltro"
                    />
                    <ErrorMessage name="tipoFiltroLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>  
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="tipoCristalLejos" class="" > Tipo de cristal: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="tipoCristalLejos"
                    name="tipoCristalLejos"
                    as="select"
                    class="form-select w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteLejos.tipoCristal"
                    >
                    <option class="text-gray-300" value="" disabled selected hidden>Seleccione...</option>
                    <option v-for="valor in tiposCristal" :key="valor" :value="valor"> {{ valor }} </option>
                    </Field>
                    <ErrorMessage name="tipoCristalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>     
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="armazonLejos" class="" > Armazón: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="armazonLejos"
                    name="armazonLejos"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteLejos.armazon"
                    />
                    <ErrorMessage name="armazonLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="dpLejos" class="" > DP: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="dpLejos"
                    name="dpLejos"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteLejos.dp"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    >
                    </Field>
                    <ErrorMessage name="dpLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>   
                </div>

              </div>
            </div>
          </div>
          
          <div class="flex justify-center sm:text-md 2xl:text-xl pb-12">
            <div class="relative">
              <div>
                <label for="valorLenteLejos" class="text-blue-700 font-semibold" > Valor lente: </label>
              </div>
              <div>
                <Field
                id="valorLenteLejos"
                name="valorLenteLejos"
                class="form-input rounded-md text-md 2xl:text-lg text-right border-gray-500"
                v-model="setValorLenteLejos"
                onkeypress="return /[0-9]/i.test(event.key)"  
                />
                <ErrorMessage name="valorLenteLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
              </div>
            </div>
          </div>
        </template>

        <!-- Lente de cerca -->
        <template v-if="currentStep === 3">
          <div class="relative border-b border-gray-300 pb-5 pt-6">
              <h2 class="text-gray-700 font-normal text-2xl 2xl:text-3xl text-center">Lente de cerca</h2>
          </div>
          <div class="inline-flex">
            <div class="grid grid-rows-2 gap-1 mb-3 p-12 sm:text-md 2xl:text-xl">
              <!-- Ojo derecho -->
              <div class="xl:flex">
                <div class="w-full xl:flex xl:w-auto">
                  <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                    <img src="../../assets/img/circulo_celeste_od_64px.png" alt="">
                  </div>
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div>
                    <label for="esfericoDerechoCerca" class="" > Esférico: </label>
                  </div>
                  <div class="relative">
                    <Field 
                      id="esfericoDerechoCerca"
                      name="esfericoDerechoCerca"
                      class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                      v-model="lenteCerca.esfericoDerecho"
                      onkeypress="return /[0-9.-]/i.test(event.key)"
                      >
                      </Field>
                      <ErrorMessage name="esfericoDerechoCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div class="">
                    <label for="cilindricoDerechoCerca" class="" > Cilíndrico: </label>
                  </div>
                  <div class="relative">
                    <Field
                    id="cilindricoDerechoCerca"
                    name="cilindricoDerechoCerca"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteCerca.cilindricoDerecho"
                    onkeypress="return /[0-9.-]/i.test(event.key)"
                    >
                    </Field>
                    <ErrorMessage name="cilindricoDerechoCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div>
                    <label for="gradosCilindricoDerechoCerca" class="" > Grados: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="gradosCilindricoDerechoCerca"
                    name="gradosCilindricoDerechoCerca"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteCerca.gradosCilindricoDerecho"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    />
                    <ErrorMessage name="gradosCilindricoDerechoCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

              </div>

              <!-- Ojo izquierdo -->
              <div class="xl:flex">
                <div class="w-full xl:flex xl:w-auto">
                  <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                    <img src="../../assets/img/circulo_celeste_oi_64px.png" alt="">
                  </div>
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div>
                    <label for="esfericoIzquierdoCerca" class="" > Esférico: </label>     
                  </div>
                  <div class="relative">
                    <Field 
                      id="esfericoIzquierdoCerca"
                      name="esfericoIzquierdoCerca"
                      class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                      v-model="lenteCerca.esfericoIzquierdo"
                      onkeypress="return /[0-9.-]/i.test(event.key)"
                      >
                      </Field>
                      <ErrorMessage name="esfericoIzquierdoCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>   
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div class="">
                    <label for="cilindricoIzquierdoCerca" class="" > Cilíndrico: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="cilindricoIzquierdoCerca"
                    name="cilindricoIzquierdoCerca"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteCerca.cilindricoIzquierdo"
                    onkeypress="return /[0-9.-]/i.test(event.key)"
                    >
                    </Field>
                    <ErrorMessage name="cilindricoIzquierdoCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

                <div class="relative text-left px-3 mb-4">
                  <div>
                    <label for="gradosCilindricoIzquierdoCerca" class="" > Grados: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="gradosCilindricoIzquierdoCerca"
                    name="gradosCilindricoIzquierdoCerca"
                    type="text"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteCerca.gradosCilindricoIzquierdo"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    />
                    <ErrorMessage name="gradosCilindricoIzquierdoCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div class="border-t border-gray-300 mb-3 px-12 pb-12 text-sm 2xl:text-lg">
            <div class="inline-flex">
              <div class="grid sm:grid-cols-2 grid-cols-1 gap-1 pt-6">

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="tipoFiltroCerca" class="" > Tipo de producto: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="tipoFiltroCerca"
                    name="tipoFiltroCerca"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteCerca.tipoFiltro"
                    />
                    <ErrorMessage name="tipoFiltroCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="tipoCristalCerca" class="" > Tipo de cristal: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="tipoCristalCerca"
                    name="tipoCristalCerca"
                    as="select"
                    class="form-select w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteCerca.tipoCristal"
                    >
                    <option class="text-gray-300" value="" disabled selected hidden>Seleccione...</option>
                    <option v-for="valor in tiposCristal" :key="valor" :value="valor"> {{ valor }} </option>
                    </Field>
                    <ErrorMessage name="tipoCristalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>   
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="armazonCerca" class="" > Armazón: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="armazonCerca"
                    name="armazonCerca"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteCerca.armazon"
                    />
                    <ErrorMessage name="armazonCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div> 
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="dpCerca" class="" > DP: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="dpCerca"
                    name="dpCerca"
                    class="form-input w-full mt-1 rounded-md sm:text-md 2xl:text-xl border-gray-500"
                    v-model="lenteCerca.dp"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    >
                    </Field>
                    <ErrorMessage name="dpCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div> 
                </div>
                
              </div>
            </div>
          </div>

          <div class="flex justify-center sm:text-md 2xl:text-xl pb-12">
            <div class="relative">
              <div>
                <label for="valorLenteCerca" class="text-blue-700 font-semibold" > Valor lente: </label>
              </div>
              <div>
                <Field
                id="valorLenteCerca"
                name="valorLenteCerca"
                class="form-input rounded-md text-md 2xl:text-lg text-right border-gray-500"
                v-model="setValorLenteCerca"
                onkeypress="return /[0-9]/i.test(event.key)"
                />
                <ErrorMessage name="valorLenteCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
              </div>
            </div>
          </div>

        </template>

        <!-- Lente multifocal -->
        <template v-if="currentStep === 4">

          <div class="relative border-b border-gray-300 pb-5 pt-6">
              <h2 class="text-gray-700 font-normal text-2xl 2xl:text-3xl text-center">Lente multifocal</h2>
          </div>

          <div class="flex flex-col">
            <div class="flex justify-center">
              <div class="flex flex-col">
                <div class="">
                  <div class="sm:col-span-2 text-xl 2xl:text-2xl pt-6 text-left pl-3">Lejos</div>
                </div>
                <div class="inline-flex">
                  <div class="grid grid-rows-2 gap-1 mb-3 p-12 sm:text-md 2xl:text-xl">
                    <!-- Ojo derecho - Lente de lejos -->
                    <div class="xl:flex">
                      <div class="w-full xl:flex xl:w-auto">
                        <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                          <img src="../../assets/img/circulo_celeste_od_64px.png" alt="">
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="esfericoDerechoMultifocalLejos" class="" > Esférico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                            id="esfericoDerechoMultifocalLejos"
                            name="esfericoDerechoMultifocalLejos"
                            class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                            v-model="lenteMultifocal.lejos.esfericoDerecho"
                            onkeypress="return /[0-9.-]/i.test(event.key)"
                            >
                            </Field>
                            <ErrorMessage name="esfericoDerechoMultifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>  
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div class="">
                          <label for="cilindricoDerechoMultifocalLejos"> Cilíndrico: </label>
                        </div>
                        <div class="relative">
                          <Field
                          id="cilindricoDerechoMultifocalLejos"
                          name="cilindricoDerechoMultifocalLejos"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteMultifocal.lejos.cilindricoDerecho"
                          onkeypress="return /[0-9.-]/i.test(event.key)"
                          >
                          </Field>
                          <ErrorMessage name="cilindricoDerechoMultifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="gradosCilindricoDerechoMultifocalLejos" class="" > Grados: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="gradosCilindricoDerechoMultifocalLejos"
                          name="gradosCilindricoDerechoMultifocalLejos"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteMultifocal.lejos.gradosCilindricoDerecho"
                          onkeypress="return /[0-9]/i.test(event.key)"
                          />
                          <ErrorMessage name="gradosCilindricoDerechoMultifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>
                    </div>

                    <!-- Ojo izquierdo - Lente de lejos -->
                    <div class="xl:flex">
                      <div class="w-full xl:flex xl:w-auto">
                        <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                          <img src="../../assets/img/circulo_celeste_oi_64px.png" alt="">
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="esfericoIzquierdoMultifocalLejos" class="" > Esférico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                            id="esfericoIzquierdoMultifocalLejos"
                            name="esfericoIzquierdoMultifocalLejos"
                            class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                            v-model="lenteMultifocal.lejos.esfericoIzquierdo"
                            onkeypress="return /[0-9.-]/i.test(event.key)"
                            >
                            </Field>
                            <ErrorMessage name="esfericoIzquierdoMultifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div> 
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="cilindricoIzquierdoMultifocalLejos"> Cilíndrico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="cilindricoIzquierdoMultifocalLejos"
                          name="cilindricoIzquierdoMultifocalLejos"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteMultifocal.lejos.cilindricoIzquierdo"
                          onkeypress="return /[0-9.-]/i.test(event.key)"
                          >
                          </Field>
                          <ErrorMessage name="cilindricoIzquierdoMultifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="gradosCilindricoIzquierdoMultifocalLejos" class="" > Grados: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="gradosCilindricoIzquierdoMultifocalLejos"
                          name="gradosCilindricoIzquierdoMultifocalLejos"
                          type="text"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteMultifocal.lejos.gradosCilindricoIzquierdo"
                          onkeypress="return /[0-9]/i.test(event.key)"
                          />
                          <ErrorMessage name="gradosCilindricoIzquierdoMultifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-center">
              <div class="relative mt-2 mb-2 text-left px-3">
                <div>
                  <label for="dpMultifocalLejos" class="" > DP: </label>
                </div>
                <div class="relative">
                  <Field 
                    id="dpMultifocalLejos"
                    name="dpMultifocalLejos"
                    class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                    v-model="lenteMultifocal.lejos.dp"
                    onkeypress="return /[0-9]/i.test(event.key)"                    
                    >
                    </Field>
                    <ErrorMessage name="dpMultifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                </div>  
              </div>
            </div>

            <div class="flex justify-center">
              <div class="flex flex-col">
                <div>
                  <div class="sm:col-span-2 text-xl 2xl:text-2xl pt-6 text-left pl-3">Cerca</div>
                </div>
                <div class="inline-flex">
                  <div class="grid grid-rows-2 gap-1 mb-3 p-12 sm:text-md 2xl:text-xl">
                    <!-- Ojo derecho - Lente de cerca -->
                    <div class="xl:flex">
                      <div class="w-full xl:flex xl:w-auto">
                        <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                          <img src="../../assets/img/circulo_celeste_od_64px.png" alt="">
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="esfericoDerechoMultifocalCerca" class="" > Esférico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                            id="esfericoDerechoMultifocalCerca"
                            name="esfericoDerechoMultifocalCerca"
                            class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                            v-model="lenteMultifocal.cerca.esfericoDerecho"
                            onkeypress="return /[0-9.-]/i.test(event.key)"
                            >
                            </Field>
                            <ErrorMessage name="esfericoDerechoMultifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="cilindricoDerechoMultifocalCerca"> Cilíndrico: </label>
                        </div>
                        <div class="relative">
                          <Field
                          id="cilindricoDerechoMultifocalCerca"
                          name="cilindricoDerechoMultifocalCerca"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteMultifocal.cerca.cilindricoDerecho"
                          onkeypress="return /[0-9.-]/i.test(event.key)"
                          >
                          </Field>
                          <ErrorMessage name="cilindricoDerechoMultifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="gradosCilindricoDerechoMultifocalCerca" class="" > Grados: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="gradosCilindricoDerechoMultifocalCerca"
                          name="gradosCilindricoDerechoMultifocalCerca"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteMultifocal.cerca.gradosCilindricoDerecho"
                          onkeypress="return /[0-9]/i.test(event.key)"
                          />
                          <ErrorMessage name="gradosCilindricoDerechoMultifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>
                    </div>

                    <!-- Ojo izquierdo - Lente cerca -->
                    <div class="xl:flex">
                      <div class="w-full xl:flex xl:w-auto">
                        <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                          <img src="../../assets/img/circulo_celeste_oi_64px.png" alt="">
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="esfericoIzquierdoMultifocalCerca" class="" > Esférico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                            id="esfericoIzquierdoMultifocalCerca"
                            name="esfericoIzquierdoMultifocalCerca"
                            class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                            v-model="lenteMultifocal.cerca.esfericoIzquierdo"
                            onkeypress="return /[0-9.-]/i.test(event.key)"
                            >
                            </Field>
                            <ErrorMessage name="esfericoIzquierdoMultifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="cilindricoIzquierdoMultifocalCerca" class="" > Cilíndrico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="cilindricoIzquierdoMultifocalCerca"
                          name="cilindricoIzquierdoMultifocalCerca"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteMultifocal.cerca.cilindricoIzquierdo"
                          onkeypress="return /[0-9.-]/i.test(event.key)"
                          >
                          </Field>
                          <ErrorMessage name="cilindricoIzquierdoMultifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="gradosCilindricoIzquierdoMultifocalCerca" class="" > Grados: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="gradosCilindricoIzquierdoMultifocalCerca"
                          name="gradosCilindricoIzquierdoMultifocalCerca"
                          type="text"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteMultifocal.cerca.gradosCilindricoIzquierdo"
                          onkeypress="return /[0-9]/i.test(event.key)"
                          />
                          <ErrorMessage name="gradosCilindricoIzquierdoMultifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>   
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          
            <div class="flex justify-center pb-4">
              <div class="relative mt-2 mb-2 text-left px-3">
                <div>
                  <label for="dpMultifocalCerca" class="" > DP: </label>
                </div>
                <div class="relative">
                  <Field 
                    id="dpMultifocalCerca"
                    name="dpMultifocalCerca"
                    class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                    v-model="lenteMultifocal.cerca.dp"
                    onkeypress="return /[0-9]/i.test(event.key)"                    
                    >
                    </Field>
                    <ErrorMessage name="dpMultifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                </div>
              </div>
            </div>
            
          </div>
          
          <div class="border-t border-gray-300 mb-3 px-12 pb-12 text-sm 2xl:text-lg">
            <div class="inline-flex">
              <div class="grid sm:grid-cols-2 grid-cols-1 gap-1 pt-6">
                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="tipoFiltroMultifocal" class="" > Tipo de producto: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="tipoFiltroMultifocal"
                    name="tipoFiltroMultifocal"
                    class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                    v-model="lenteMultifocal.tipoFiltro"
                    />
                    <ErrorMessage name="tipoFiltroMultifocal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="tipoCristalMultifocal" class="" > Tipo de cristal: </label>
                  </div>
                  <div class="relative">
                    <Field 
                      id="tipoCristalMultifocal"
                      name="tipoCristalMultifocal"
                      as="select"
                      class="form-select w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                      v-model="lenteMultifocal.tipoCristal"
                      >
                      <option class="text-gray-300" value="" disabled selected hidden>Seleccione...</option>
                      <option v-for="valor in tiposCristal" :key="valor" :value="valor"> {{ valor }} </option>
                      </Field>
                      <ErrorMessage name="tipoCristalMultifocal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>  
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="armazonMultifocal" class="" > Armazón: </label>
                  </div>
                  <div class="relative">
                    <Field 
                      id="armazonMultifocal"
                      name="armazonMultifocal"
                      class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                      v-model="lenteMultifocal.armazon"
                      />
                      <ErrorMessage name="armazonMultifocal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>  
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="tipoMultifocal" class="" > Tipo multifocal: </label>
                  </div>
                  <div class="relative">
                    <Field 
                      id="tipoMultifocal"
                      name="tipoMultifocal"
                      class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                      v-model="lenteMultifocal.tipoMultifocal"
                      >
                      </Field>
                      <ErrorMessage name="tipoMultifocal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
            
          <div class="flex justify-center sm:text-md 2xl:text-xl pb-12">
            <div class="relative">
              <div>
                <label for="valorLenteMultifocalCerca" class="text-blue-700 font-semibold" > Valor lente: </label>
              </div>
              <div>
                <Field
                id="valorLenteMultifocalCerca"
                name="valorLenteMultifocalCerca"
                class="form-input rounded-md text-md 2xl:text-lg text-right border-gray-500"
                v-model="setValorLenteMultifocal"
                onkeypress="return /[0-9]/i.test(event.key)"
                />
                <ErrorMessage name="valorLenteMultifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
              </div>
            </div>
          </div>
        </template>
        
        <!-- Lente de bifocal -->
        <template v-if="currentStep === 5">
          <div class="relative border-b border-gray-300 pb-5 pt-6">
              <h2 class="text-gray-700 font-normal text-2xl 2xl:text-3xl text-center">Lente bifocal</h2>
          </div>
          
          <div class="flex flex-col">
            <div class="flex justify-center">
              <div class="flex flex-col">
                <div>
                  <div class="sm:col-span-2 text-xl 2xl:text-2xl pt-6 text-left pl-3">Lejos</div>
                </div>
                <div class="inline-flex">
                  <div class="grid grid-rows-2 gap-1 mb-3 p-12 sm:text-md 2xl:text-xl">
                    <!-- Ojo derecho - Lente de lejos -->
                    <div class="xl:flex">
                      <div class="w-full xl:flex xl:w-auto">
                        <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                          <img src="../../assets/img/circulo_celeste_od_64px.png" alt="">
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="esfericoDerechoBifocalLejos" class="" > Esférico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                            id="esfericoDerechoBifocalLejos"
                            name="esfericoDerechoBifocalLejos"
                            class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                            v-model="lenteBifocal.lejos.esfericoDerecho"
                            onkeypress="return /[0-9.-]/i.test(event.key)"
                            >
                            </Field>
                            <ErrorMessage name="esfericoDerechoBifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>  
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="cilindricoDerechoBifocalLejos"> Cilíndrico: </label>
                        </div>
                        <div class="relative">
                          <Field
                          id="cilindricoDerechoBifocalLejos"
                          name="cilindricoDerechoBifocalLejos"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteBifocal.lejos.cilindricoDerecho"
                          onkeypress="return /[0-9.-]/i.test(event.key)"
                          >
                          </Field>
                          <ErrorMessage name="cilindricoDerechoBifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="gradosCilindricoDerechoBifocalLejos" class="" > Grados: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="gradosCilindricoDerechoBifocalLejos"
                          name="gradosCilindricoDerechoBifocalLejos"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteBifocal.lejos.gradosCilindricoDerecho"
                          onkeypress="return /[0-9]/i.test(event.key)"
                          />
                          <ErrorMessage name="gradosCilindricoDerechoBifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>
                    </div>

                    <!-- Ojo izquierdo - Lente de lejos -->
                    <div class="xl:flex">
                      <div class="w-full xl:flex xl:w-auto">
                        <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                          <img src="../../assets/img/circulo_celeste_oi_64px.png" alt="">
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="esfericoIzquierdoBifocalLejos" class="" > Esférico ojo izquierdo: </label>      
                        </div>
                        <div class="relative">
                          <Field 
                          id="esfericoIzquierdoBifocalLejos"
                          name="esfericoIzquierdoBifocalLejos"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteBifocal.lejos.esfericoIzquierdo"
                          onkeypress="return /[0-9.-]/i.test(event.key)"
                          >
                          </Field>
                          <ErrorMessage name="esfericoIzquierdoBifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>  
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="cilindricoIzquierdoBifocalLejos"> Cilíndrico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="cilindricoIzquierdoBifocalLejos"
                          name="cilindricoIzquierdoBifocalLejos"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteBifocal.lejos.cilindricoIzquierdo"
                          onkeypress="return /[0-9.-]/i.test(event.key)"
                          >
                          </Field>
                          <ErrorMessage name="cilindricoIzquierdoBifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="gradosCilindricoIzquierdoBifocalLejos" class="" > Grados: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="gradosCilindricoIzquierdoBifocalLejos"
                          name="gradosCilindricoIzquierdoBifocalLejos"
                          type="text"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteBifocal.lejos.gradosCilindricoIzquierdo"
                          onkeypress="return /[0-9]/i.test(event.key)"
                          />
                          <ErrorMessage name="gradosCilindricoIzquierdoBifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-center">
              <div class="relative mt-2 mb-2 text-left px-3">
                <div>
                  <label for="dpBifocalLejos" class="" > DP: </label>
                </div>
                <div class="relative">
                  <Field 
                    id="dpBifocalLejos"
                    name="dpBifocalLejos"
                    class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                    v-model="lenteBifocal.lejos.dp"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    >
                    </Field>
                    <ErrorMessage name="dpBifocalLejos" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                </div>    
              </div>
            </div>

            <div class="flex justify-center">
              <div class="flex flex-col">
                <div>
                  <div class="sm:col-span-2 text-2xl pt-6 text-left pl-3">Cerca</div>
                </div>
                <div class="inline-flex">
                  <div class="grid grid-rows-2 gap-1 mb-3 p-12 sm:text-md 2xl:text-xl">
                    <!-- Ojo derecho - Lente de cerca -->
                    <div class="xl:flex">
                      <div class="w-full xl:flex xl:w-auto">
                        <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                          <img src="../../assets/img/circulo_celeste_od_64px.png" alt="">
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="esfericoDerechoBifocalCerca" class="" > Esférico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                            id="esfericoDerechoBifocalCerca"
                            name="esfericoDerechoBifocalCerca"
                            class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                            v-model="lenteBifocal.cerca.esfericoDerecho"
                            onkeypress="return /[0-9.-]/i.test(event.key)"
                            >
                            </Field>
                            <ErrorMessage name="esfericoDerechoBifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>  
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="cilindricoDerechoBifocalCerca" class="" > Cilíndrico: </label>
                        </div>
                        <div class="relative">
                          <Field
                          id="cilindricoDerechoBifocalCerca"
                          name="cilindricoDerechoBifocalCerca"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteBifocal.cerca.cilindricoDerecho"
                          onkeypress="return /[0-9.-]/i.test(event.key)"
                          >
                          </Field>
                          <ErrorMessage name="cilindricoDerechoBifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="gradosCilindricoDerechoBifocalCerca" class="" > Grados: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="gradosCilindricoDerechoBifocalCerca"
                          name="gradosCilindricoDerechoBifocalCerca"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteBifocal.cerca.gradosCilindricoDerecho"
                          onkeypress="return /[0-9]/i.test(event.key)"
                          />
                          <ErrorMessage name="gradosCilindricoDerechoBifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>
                    </div>

                    <!-- Ojo izquierdo - Lente cerca -->
                    <div class="xl:flex">
                      <div class="w-full xl:flex xl:w-auto">
                        <div class="self-end flex justify-center px-4 xl:w-20 mb-4">
                          <img src="../../assets/img/circulo_celeste_oi_64px.png" alt="">
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="esfericoIzquierdoBifocalCerca" class="" > Esférico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                            id="esfericoIzquierdoBifocalCerca"
                            name="esfericoIzquierdoBifocalCerca"
                            class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                            v-model="lenteBifocal.cerca.esfericoIzquierdo"
                            onkeypress="return /[0-9.-]/i.test(event.key)"
                            >
                            </Field>
                            <ErrorMessage name="esfericoIzquierdoBifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div> 
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="cilindricoIzquierdoBifocalCerca" class="" > Cilíndrico: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="cilindricoIzquierdoBifocalCerca"
                          name="cilindricoIzquierdoBifocalCerca"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteBifocal.cerca.cilindricoIzquierdo"
                          onkeypress="return /[0-9.-]/i.test(event.key)"
                          >
                          </Field>
                          <ErrorMessage name="cilindricoIzquierdoBifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>

                      <div class="relative text-left px-3 mb-4">
                        <div>
                          <label for="gradosCilindricoIzquierdoBifocalCerca" class="" > Grados: </label>
                        </div>
                        <div class="relative">
                          <Field 
                          id="gradosCilindricoIzquierdoBifocalCerca"
                          name="gradosCilindricoIzquierdoBifocalCerca"
                          type="text"
                          class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                          v-model="lenteBifocal.cerca.gradosCilindricoIzquierdo"
                          onkeypress="return /[0-9]/i.test(event.key)"
                          />
                          <ErrorMessage name="gradosCilindricoIzquierdoBifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="flex justify-center pb-4">
              <div class="relative mt-2 mb-2 text-left px-3">
                <div>
                  <label for="dpBifocalCerca" class="" > DP: </label>
                </div>
                <div class="relative">
                  <Field 
                    id="dpBifocalCerca"
                    name="dpBifocalCerca"
                    class="form-input w-full mt-1 rounded-md  text-sm 2xl:text-lg border-gray-500"
                    v-model="lenteBifocal.cerca.dp"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    >
                    </Field>
                    <ErrorMessage name="dpBifocalCerca" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                </div>    
              </div>
            </div>

          </div>

          <div class="border-t border-gray-300 mb-3 px-12 pb-12 text-sm 2xl:text-lg">
            <div class="inline-flex">
              <div class="grid sm:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-1 pt-6">
                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="tipoFiltroBifocal" class="" > Tipo de producto: </label>
                  </div>
                  <div class="relative">
                    <Field 
                    id="tipoFiltroBifocal"
                    name="tipoFiltroBifocal"
                    class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                    v-model="lenteBifocal.tipoFiltro"
                    />
                    <ErrorMessage name="tipoFiltroBifocal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="tipoCristalBifocal" class="" > Tipo de cristal: </label>
                  </div>
                  <div class="relative">
                    <Field 
                      id="tipoCristalBifocal"
                      name="tipoCristalBifocal"
                      as="select"
                      class="form-select w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                      v-model="lenteBifocal.tipoCristal"
                      >
                      <option class="text-gray-300" value="" disabled selected hidden>Seleccione...</option>
                      <option v-for="valor in tiposCristal" :key="valor" :value="valor"> {{ valor }} </option>
                      </Field>
                      <ErrorMessage name="tipoCristalBifocal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>   
                </div>

                <div class="relative mt-2 mb-2 text-left px-3">
                  <div>
                    <label for="armazonBifocal" class="" > Armazón: </label>
                  </div>
                  <div class="relative">
                    <Field 
                      id="armazonBifocal"
                      name="armazonBifocal"
                      class="form-input w-full mt-1 rounded-md text-sm 2xl:text-lg border-gray-500"
                      v-model="lenteBifocal.armazon"
                      />
                      <ErrorMessage name="armazonBifocal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
                  </div>  
                </div>

              </div>
            </div>
            
          </div>

          <div class="flex justify-center sm:text-md 2xl:text-xl pb-12">
            <div class="relative">
              <div>
                <label for="valorLenteBifocal" class="text-blue-700 font-semibold" > Valor lente: </label>
              </div>
              <div>
                <Field
                id="valorLenteBifocal"
                name="valorLenteBifocal"
                class="form-input rounded-md text-md 2xl:text-lg text-right border-gray-500"
                v-model="setValorLenteBifocal"
                onkeypress="return /[0-9]/i.test(event.key)"
                />
                <ErrorMessage name="valorLenteBifocal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
              </div>
            </div>
          </div>
        </template>

        <!-- Datos finales -->
        <template v-if="currentStep === 6">
          <div class="p-12 inline-flex flex-col">
            <div class="flex flex-col relative text-xl 2xl:text-2xl text-left">
              <label for="observacion" class="text-left" > Observación: </label>
                <Field 
                as="textarea"
                id="observacion"
                name="observacion"
                class="form-input mt-1 rounded-md resize-none text-xl 2xl:text-2xl w-full border-gray-500"
                v-model="cotizacion.observacion"
                />
                <ErrorMessage name="observacion" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
            </div>

            <div class="flex flex-col relative text-xl 2xl:text-2xl text-left mt-6">
              <label for="laboratorio" class="text-left" > Laboratorio: </label>
                <Field 
                id="laboratorio"
                name="laboratorio"
                class="form-input mt-1 rounded-md resize-none text-xl 2xl:text-2xl w-full border-gray-500"
                v-model="cotizacion.laboratorio"
                />
                <ErrorMessage name="laboratorio" class="absolute text-sm text-red-600 bottom-0 right-0 -bottom-5 text-right"/>
            </div>

            <div class="flex flex-col relative text-xl 2xl:text-2xl text-left mt-6">
              <label for="nombreVendedor" class="text-left" > Nombre del asesor: </label>
                <Field 
                id="nombreVendedor"
                name="nombreVendedor"
                class="form-input mt-1 rounded-md resize-none text-xl 2xl:text-2xl w-full border-gray-500"
                v-model="cotizacion.nombreVendedor"
                />
                <ErrorMessage name="nombreVendedor" class="absolute text-sm text-red-600 bottom-0 right-0 -bottom-5 text-right"/>
            </div>

            <div class="text-lg 2xl:text-xl mt-6 relative">
                <label for="valorTotal" class="text-blue-700 font-semibold" > Valor total: </label>
                  <Field
                  id="valorTotal"
                  name="valorTotal"
                  class="form-input rounded-md text-lg 2xl:text-xl text-right w-full border-gray-500"
                  v-model="this.valorTotalFormateado"
                  :disabled="true"
                  />
                  <ErrorMessage name="valorTotal" class="absolute text-sm text-red-600 inset-x-0 -bottom-5 text-right"/>
            </div>
          </div>

          
        </template>

        <div class="border-t border-gray-300 py-4">
          <button 
            class="bg-white inline-flex items-center rounded-full focus:outline-none mr-1" 
            v-if="currentStep !== 0" 
            type="button" 
            @click="prevStep"
            title="Atrás"
          >
          <faIcon icon="arrow-circle-left" type="fa" class="text-blue-500 text-5xl hover:text-blue-700"></faIcon>
          </button>

          <button class="bg-white inline-flex items-center rounded-full focus:outline-none ml-1"
            v-if="currentStep !== 6"
            type="button"
            @click="handleSubmit($event, nextStep, this.mostrarValorTotal())"
            title="Avanzar"
          >
            <faIcon icon="arrow-circle-right" type="fa" class="text-blue-500 text-5xl hover:text-blue-700"></faIcon>
          </button>
        </div>

        <div class="pb-3" v-if="currentStep == 6">
            <button 
              type="submit"
              class="mx-1 my-3 py-4 px-6 bg-blue-700 text-white text-lg font-semibold rounded-lg shadow-md focus:outline-none hover:bg-blue-600"
            >
            Cotizar
            </button>

            <button 
              type="button"
              class="mx-1 my-3 py-4 px-6 bg-green-700 text-white text-lg font-semibold rounded-lg shadow-md focus:outline-none hover:bg-green-600"
              @click="handleSubmit($event, abrirModalConfirmacionOT)"
            >
            Generar OT
            </button>
        </div>
      </Form>
  </div>
  
  <!-- Modal de confirmación de Cotización -->
  <Modal
  v-if="esVisibleModal"
  titulo="¡Un momento!"
  parrafo="Vas a ingresar una nueva cotización con los datos especificados anteriormente."
  pregunta="¿Deseas continuar?"
  class="z-40"
  >
  <template v-slot:botones>
    <div class="justify-center">
      <button 
      @click="ingresarCotizacion"
      :disabled="estaInhabilitadoBotonCotizar"
      class="bg-green-600 hover:bg-green-500 rounded-md border border-transparent shadow-md mx-1 px-5 py-3 text-md font-semibold focus:outline-none sm:ml-3 sm:w-auto text-white">
          ¡OK!
      </button>
      <button 
      @click="cerrarModal"
      class="rounded-md border shadow-md mx-1 px-5 py-3 text-md font-semibold focus:outline-none sm:mt-0 sm:w-auto border-transparent bg-gray-600 hover:bg-gray-500 text-white">
          No
      </button>
    </div>
  </template>
  </Modal>

<!-- Modal de confirmación de OT -->
  <Modal
      :titulo="'¡Un momento!'"
      v-if="modalConfirmacionOT"
      class="z-40"
    >
    <template v-slot:body>
      <div>
        <Form @submit="ingresarOT" :validation-schema="schema">
          <div class="flex flex-col">
            <p class="pt-6 text-gray-700 text-lg">
              Se ingresará la OT luego de realizar este primer abono: 
            </p>
            <div class="pb-8 pt-4 flex justify-center">
              <div class="flex flex-col text-center px-2 pt-3 relative text-xl max-w-sm">

                <div class="flex items-center">
                  <label class="mr-1 font-normal text-gray-900">Monto:</label>
                </div>
                <div class="relative">
                  <Field 
                    name="montoAbono"
                    class="form-input rounded-md text-right text-xl w-full border-gray-500 text-gray-900"
                    v-model="setMontoAbono"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    > 
                  </Field>
                  <ErrorMessage name="montoAbono" class="block absolute text-sm text-red-600 inset-x-0 text-right mr-2"/>
                </div>
                
                <div class="flex items-center pt-4">
                  <label class="font-normal text-gray-900">Medio pago:</label>
                </div>
                <div class="relative">
                  <Field 
                    name="medioPagoAbono"
                    as="select"
                    class="form-select rounded-md text-xl w-full border-gray-500 text-gray-900"
                    v-model="this.datosOT.abono.medioPagoAbono"
                    >
                    <option value="Efectivo"> Efectivo </option>
                    <option value="Transbank" selected> Transbank </option>
                    <option value="Cheque"> Cheque </option>
                    <option value="Convenio"> Convenio </option>
                  </Field>
                </div>

                <div class="flex items-center pt-4">
                  <label class="font-normal text-gray-900">Nº boleta:</label>
                </div>
                <div class="relative">
                  <Field 
                    name="numeroBoleta"
                    class="form-input rounded-md text-left text-xl w-full border-gray-500 text-gray-900"
                    v-model="datosOT.numeroBoleta"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    > 
                  </Field>
                  <ErrorMessage name="numeroBoleta" class="block absolute text-sm text-red-600 inset-x-0 text-right mr-2"/>
                </div>

                <div class="flex items-center pt-4">
                  <label class="font-normal text-gray-900">Fecha de retiro:</label>
                </div>
                <div class="relative">
                  <DatePicker
                  :attributes="atributosDatePicker"
                  v-model="datosOT.fechaRetiro"
                  mode="date"
                  :model-config="modelConfig">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                        name="fechaRetiro"
                        class="rounded-md w-full text-xl form-input box border-gray-500 text-center"
                        :value="inputValue"
                        v-on="inputEvents"
                        >
                    </template>
                  </DatePicker>

                  <ErrorMessage name="fechaRetiro" class="block absolute text-sm text-red-600 inset-x-0 text-right mr-2"/>
                </div>

                <div class="pt-4 text-gray-900">
                  Crédito:
                  <input type="checkbox" class="form-checkbox text-2xl border-gray-500" title="Esta opción es para definir si se le fía al cliente o no." v-model="datosOT.credito">
                  <button 
                type="submit" 
                :disabled="estaInhabilitadoBotonAbono"
                class="mt-4 justify-center rounded-md border border-transparent shadow-sm px-4 py-3 text-base font-semibold focus:outline-none ml-3 sm:w-auto sm:text-md bg-green-600 hover:bg-green-500 text-white">
                    Abonar
                </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </template>

    <template v-slot:botones>
      <div>
        <button 
        @click="cerrarModalConfirmacionOT"
        class="rounded-md border shadow-md mx-1 px-6 py-3 text-base font-semibold focus:outline-none sm:mt-0 sm:w-auto sm:text-sm border-transparent bg-gray-600 hover:bg-gray-500 text-white">
          Cancelar
        </button>
      </div>
    </template>
  </Modal>

  <Modal
  titulo="Voucher cotización"
  textoBotonAction="Imprimir"
  class="z-40"
  @accion="imprimirVoucher('voucherCotz')"
  @cerrar="cerrarModalVoucherCotz"
  v-if="esVisibleVoucherCotz"
  >
  <template v-slot:body>
    <Voucher
    id="voucherCotz">
      <template v-slot:contenido>
        <VoucherCotizacion
        :datos="datosVoucherCotz"
        />
      </template>
    </Voucher>
  </template>
  </Modal>

  <Modal
  titulo="Voucher OT"
  textoBotonAction="Imprimir"
  class="z-40"
  @accion="imprimirVoucher('voucherOT')"
  @cerrar="cerrarModalVoucherOT"
  v-if="esVisibleVoucherOT"
  >
  <template v-slot:body>
    <Voucher
    id="voucherOT">
      <template v-slot:contenido>
        <VoucherOT
        :datosOT="datosVoucherOT"
      />
      </template>
    </Voucher>
  </template>
  </Modal>

</template>

<script>
import Modal from '@/components/Modal';
import Voucher from '@/components/Voucher';
import VoucherCotizacion from '@/components/cotizaciones/VoucherCotizacion';
import VoucherOT from '@/components/OTs/VoucherOT';
import { DatePicker } from "v-calendar"

import { Field, Form, ErrorMessage } from "vee-validate";
import { number, object, string } from "yup";
import { eliminarVacios, separarPorMiles, printSection } from "@/utils/helpers";
import { ref } from "vue";
import moment from "moment"

import CotizacionService from "@/services/CotizacionService";
import OTService from "@/services/OrdenTrabajoService";

export default {
    name: "FormCotizacion",
    setup() {
      const currentStep = ref(0);
      const lejosSeleccionado = ref(false);
      const cercaSeleccionado = ref(false);
      const multifocalSeleccionado = ref(false);
      const bifocalSeleccionado = ref(false);

      const schema = object({
        nombre: string().required('El nombre es obligatorio.'),
        apellidoPaterno: string().required('El apellido paterno es obligatorio.'),
        apellidoMaterno: string().required('El apellido materno es obligatorio.'),
        laboratorio: string().required('Ingresa un laboratorio.'),
        nombreVendedor: string().required('Ingresa el nombre del vendedor.'),
        telefono: string().required('El teléfono es obligatorio.'),
        valorTotal: string().required('El valor total es obligatorio.'),
        esfericoIzquierdoLejos: string().required('Este valor es obligatorio.'),
        esfericoDerechoLejos: string().required('Este valor es obligatorio.'),
        cilindricoDerechoLejos: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        cilindricoIzquierdoLejos: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        gradosCilindricoIzquierdoLejos: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        gradosCilindricoDerechoLejos: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        armazonLejos: string().required('El armazón es obligatorio.'),
        tipoFiltroLejos: string().required('El tipo de producto es obligatorio.'),
        tipoCristalLejos: string().required('El tipo de cristal es obligatorio.'),
        valorLenteLejos: string().required('Debes ingresar un monto.'),
        dpLejos: string().required('Este campo es obligatorio.'),
        esfericoIzquierdoCerca: string().required('Este valor es obligatorio.'),
        esfericoDerechoCerca: string().required('Este valor es obligatorio.'),
        cilindricoDerechoCerca: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        cilindricoIzquierdoCerca: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        gradosCilindricoIzquierdoCerca: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        gradosCilindricoDerechoCerca: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        armazonCerca: string().required('El armazón es obligatorio.'),
        tipoFiltroCerca: string().required('El tipo de producto es obligatorio.'),
        tipoCristalCerca: string().required('El tipo de cristal es obligatorio.'),
        valorLenteCerca: string().required('Debes ingresar un monto.'),
        dpCerca: string().required('Este campo es obligatorio.'),
        esfericoIzquierdoMultifocalLejos: string().required('Este valor es obligatorio.'),
        esfericoDerechoMultifocalLejos: string().required('Este valor es obligatorio.'),
        cilindricoDerechoMultifocalLejos: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        cilindricoIzquierdoMultifocalLejos: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        cilindricoDerechoMultifocalCerca: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        cilindricoIzquierdoMultifocalCerca: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        gradosCilindricoIzquierdoMultifocalLejos: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        gradosCilindricoDerechoMultifocalLejos: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        dpMultifocalLejos: string().required('Este campo es obligatorio.'),
        esfericoIzquierdoMultifocalCerca: string().required('Este valor es obligatorio.'),
        esfericoDerechoMultifocalCerca: string().required('Este valor es obligatorio.'),
        cilindricoDerechoBifocalLejos: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        cilindricoIzquierdoBifocalLejos: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        cilindricoDerechoBifocalCerca: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        cilindricoIzquierdoBifocalCerca: number().typeError().min(-9, 'El valor mínimo es -9.').max(9, 'El valor máximo es 9.'),
        gradosCilindricoIzquierdoMultifocalCerca: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        gradosCilindricoDerechoMultifocalCerca: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        dpMultifocalCerca: string().required('Este campo es obligatorio.'),
        armazonMultifocal: string().required('El armazón es obligatorio.'),
        tipoFiltroMultifocal: string().required('El tipo de producto es obligatorio.'),
        tipoCristalMultifocal: string().required('El tipo de cristal es obligatorio.'),
        tipoMultifocal: string().required('Este valor es obligatorio.'),
        valorLenteMultifocalCerca: string().required('Debes ingresar un monto.'),   
        esfericoIzquierdoBifocalLejos: string().required('Este valor es obligatorio.'),
        esfericoDerechoBifocalLejos: string().required('Este valor es obligatorio.'),
        gradosCilindricoIzquierdoBifocalLejos: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        gradosCilindricoDerechoBifocalLejos: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        dpBifocalLejos: string().required('Este campo es obligatorio.'),
        esfericoIzquierdoBifocalCerca: string().required('Este valor es obligatorio.'),
        esfericoDerechoBifocalCerca: string().required('Este valor es obligatorio.'),
        gradosCilindricoIzquierdoBifocalCerca: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        gradosCilindricoDerechoBifocalCerca: number().typeError().min(0, 'El valor mínimo es 0.').max(180, 'El valor máximo es 180.'),
        dpBifocalCerca: string().required('Este campo es obligatorio.'),
        armazonBifocal: string().required('El armazón es obligatorio.'),
        tipoFiltroBifocal: string().required('El tipo de producto es obligatorio.'),
        tipoCristalBifocal: string().required('El tipo de cristal es obligatorio.'),
        tipoBifocal: string().required('Este valor es obligatorio.'),
        valorLenteBifocal: string().required('Debes ingresar un monto.'),
        montoAbono: string().required('Debes ingresar un monto.'),
        fechaRetiro: string().required('Debes ingresar una fecha de retiro.')
      });

      function nextStep() {
        switch (currentStep.value) {
          case 0:
            currentStep.value = 1;
            break;
            
          case 1:
            if (lejosSeleccionado.value || cercaSeleccionado.value || multifocalSeleccionado.value || bifocalSeleccionado.value) {
              if (lejosSeleccionado.value) {
                currentStep.value = 2;
              }
              else{
                if (cercaSeleccionado.value) {
                  currentStep.value = 3;
                }
                else{
                  if (multifocalSeleccionado.value) {
                    currentStep.value = 4;
                  }
                  else{
                    currentStep.value = 5;
                  }
                }
              }
            }

            break;

          case 2:
            if (cercaSeleccionado.value) {
              currentStep.value = 3;
            }
            else{
              if (multifocalSeleccionado.value) {
                currentStep.value = 4;
              }
              else{
                if (bifocalSeleccionado.value) {
                  currentStep.value = 5;
                }
                else{
                  currentStep.value = 6;
                }
              }
            }

            break;

          case 3:
            if (multifocalSeleccionado.value) {
              currentStep.value = 4;
            }
            else{
              if (bifocalSeleccionado.value) {
                currentStep.value = 5;
              }
              else{
                currentStep.value = 6;
              }
            }

            break;

          case 4:
            if (bifocalSeleccionado.value) {
              currentStep.value = 5;
            }
            else{
              currentStep.value = 6;
            }

            break;

          case 5:
            currentStep.value = 6;

            break;

          default:
            break;
        }
      }

    function prevStep() {
      switch (currentStep.value) {
          case 0:
            break;
            
          case 6:
            if (bifocalSeleccionado.value) {
              currentStep.value = 5;
            }
            else{
              if (multifocalSeleccionado.value) {
                currentStep.value = 4;
              }
              else{
                if (cercaSeleccionado.value) {
                  currentStep.value = 3;
                }
                else{
                  if (lejosSeleccionado.value) {
                    currentStep.value = 2;
                  }
                  else{
                    currentStep.value = 1;
                  }
                }
              }
            }

            break;

          case 5:
            if (multifocalSeleccionado.value) {
              currentStep.value = 4;
            }
            else{
              if (cercaSeleccionado.value) {
                currentStep.value = 3;
              }
              else{
                if (lejosSeleccionado.value) {
                  currentStep.value = 2;
                }
                else{
                  currentStep.value = 1;
                }
              }
            }

            break;

          case 4:
            if (cercaSeleccionado.value) {
              currentStep.value = 3;
            }
            else{
              if (lejosSeleccionado.value) {
                currentStep.value = 2;
              }
              else{
                currentStep.value = 1;                                
              }
            }

            break;

          case 3:
            if (lejosSeleccionado.value) {
              currentStep.value = 2;
            }
            else{ 
              currentStep.value = 1;
            }

            break;

          case 2:
            currentStep.value = 1;
            
            break;

          case 1:
            currentStep.value = 0;

            break;

          default:
            break;
        }
    }

      return {
        schema,
        currentStep,
        nextStep,
        prevStep,
        lejosSeleccionado,
        cercaSeleccionado,
        multifocalSeleccionado,
        bifocalSeleccionado
      };
    },
    data() {
      return {
        esVisibleModal: false,
        cotizacion: {
          numeroCotz: '0',
          observacion: '',
          valorTotal: 0,
          nombre: '',
          apellidoPaterno: '',
          apellidoMaterno: '',
          telefono: '',
          laboratorio: '',
          nombreVendedor: '',
          fechaIngreso: '00/00/0000',
          lentesCotizados: [],
        },
        datosOT: {
          cotizacion: null,
          abono: {
            montoAbono: 0,
            medioPagoAbono: 'Efectivo'
          },
          credito: false,
          numeroBoleta: null,
          fechaRetiro: moment(new Date()).format('DD-MM-YYYY')
        },
        error: null,
        tiposCristal: ['CR 39', 'Mineral', 'Policarbonato'],
        lenteLejos: {
            tipoLente: 'Lejos',
            esfericoIzquierdo: '',
            esfericoDerecho: '',
            cilindricoIzquierdo: '',
            cilindricoDerecho: '',
            gradosCilindricoIzquierdo: '',
            gradosCilindricoDerecho: '',
            armazon: '',
            tipoFiltro: '',
            tipoCristal: '',
            valorLente: 0,
            dp: '',
            tipoDistancia: 'Lejos'
          },
        lenteCerca: {
          tipoLente: 'Cerca',
          esfericoIzquierdo: '',
          esfericoDerecho: '',
          cilindricoIzquierdo: '',
          cilindricoDerecho: '',
          gradosCilindricoIzquierdo: '',
          gradosCilindricoDerecho: '',
          armazon: '',
          tipoFiltro: '',
          tipoCristal: '',
          valorLente: 0,
          dp: '',
          tipoDistancia: 'Cerca'
        },
        lenteMultifocal: {
          lejos: {
            esfericoIzquierdo: '',
            esfericoDerecho: '',
            cilindricoIzquierdo: '',
            cilindricoDerecho: '',
            gradosCilindricoIzquierdo: '',
            gradosCilindricoDerecho: '',
            tipoDistancia: 'Lejos',
            dp: ''
          },
          cerca: {
            esfericoIzquierdo: '',
            esfericoDerecho: '',
            cilindricoIzquierdo: '',
            cilindricoDerecho: '',
            gradosCilindricoIzquierdo: '',
            gradosCilindricoDerecho: '',
            tipoDistancia: 'Cerca',
            dp: ''
          },
          armazon: '',
          tipoFiltro: '',
          tipoCristal: '',
          tipoLente: 'Multifocal',
          valorLente: 0,
          tipoMultifocal: ''
        },
        lenteBifocal: {
          lejos: {
            esfericoIzquierdo: '',
            esfericoDerecho: '',
            cilindricoIzquierdo: '',
            cilindricoDerecho: '',
            gradosCilindricoIzquierdo: '',
            gradosCilindricoDerecho: '', 
            tipoDistancia: 'Lejos',
            dp: ''
          },
          cerca: {
            esfericoIzquierdo: '',
            esfericoDerecho: '',
            cilindricoIzquierdo: '',
            cilindricoDerecho: '',
            gradosCilindricoIzquierdo: '',
            gradosCilindricoDerecho: '', 
            tipoDistancia: 'Cerca',
            dp: ''            
          },
          armazon: '',
          tipoFiltro: '',
          tipoCristal: '',
          tipoLente: 'Bifocal',
          valorLente: 0
        },
        cilindricoLejosDerechoSeleccionado: false,
        cilindricoLejosIzquierdoSeleccionado: false,
        cilindricoCercaDerechoSeleccionado: false,
        cilindricoCercaIzquierdoSeleccionado: false,
        cilindricoMultifocalDerechoLejosSeleccionado: false,
        cilindricoMultifocalIzquierdoLejosSeleccionado: false,
        cilindricoMultifocalDerechoCercaSeleccionado: false,
        cilindricoMultifocalIzquierdoCercaSeleccionado: false,
        cilindricoBifocalDerechoLejosSeleccionado: false,
        cilindricoBifocalIzquierdoLejosSeleccionado: false,
        cilindricoBifocalDerechoCercaSeleccionado: false,
        cilindricoBifocalIzquierdoCercaSeleccionado: false,
        valorTotalFormateado: '',
        modalConfirmacionOT: false,
        estaInhabilitadoBotonAbono: false,
        estaInhabilitadoBotonCotizar: false,
        esVisibleVoucherOT: false,
        esVisibleVoucherCotz: false,
        datosVoucherOT: {
          numeroOT: 0,
          fecha: '00/00/0000',
          nombre: '',
          telefono: '',
          lentes: [],
          abono: 0,
          saldo: 0,
          total: 0,
          codigoOT: '',
          sucursal: '',
          nombreVendedor: '',
          fechaRetiro: ''
        },
        datosVoucherCotz: {
          numeroCotz: 0,
          fecha: '00/00/0000',
          nombre: '',
          lentes: [],
          total: 0,
          nombreVendedor: ''
        },
        atributosDatePicker: [
          {
              key: 'today',
              highlight: true,
              dates: null,
          }
        ],
        modelConfig: {
          type: 'string',
          mask: 'DD-MM-YYYY'
        },
      };
    },
    methods: {
      toggleLente(lente, agregado) {
        if (agregado) {
          this.cotizacion.lentesCotizados.push(lente);
        }
        else{
          let indiceEliminar;

          for (let index = 0; index < this.cotizacion.lentesCotizados.length; index++) {
            const elemento = this.cotizacion.lentesCotizados[index];
            if (elemento.tipoLente == lente.tipoLente) {
              indiceEliminar = index;
              break;
            }
          }

          this.cotizacion.lentesCotizados.splice(indiceEliminar, 1);
        }
      },
      ingresarCotizacion() {
        this.estaInhabilitadoBotonCotizar = true;
        this.error = null;

        const payload = this.cotizacion;

        eliminarVacios(payload);

        CotizacionService.storeCotizacion(payload)
        .then((response) => {
          let cotizacionIngresada = response.data;

          this.datosVoucherCotz.numeroCotz = cotizacionIngresada.cotz_num;
          this.datosVoucherCotz.fecha = cotizacionIngresada.fechaFormateada;
          this.datosVoucherCotz.nombre = `${cotizacionIngresada.cotz_nombre} ${cotizacionIngresada.cotz_apellido_pat} ${cotizacionIngresada.cotz_apellido_mat}`;
          this.datosVoucherCotz.total = cotizacionIngresada.cotz_valor_total;
          this.datosVoucherCotz.nombreVendedor = cotizacionIngresada.cotz_nombre_vendedor;

          for (let index = 0; index < this.cotizacion.lentesCotizados.length; index++) {
            const element = this.cotizacion.lentesCotizados[index];

            let lente = {
              tipo: element.tipoLente, 
              valor: element.valorLente,
              armazon: element.armazon,
              filtro: element.tipoFiltro,
              cristal: element.tipoCristal
            };

            if (element.tipoLente == 'Multifocal') {
              lente.tipoMultifocal = element.tipoMultifocal;
            }

            this.datosVoucherCotz.lentes.push(lente);
          }

          this.esVisibleVoucherCotz = true;
        })
        .then(() => {
          this.limpiarCampos();
          this.currentStep = 0;
          this.cerrarModal();
          this.emitter.emit("notification-set", {
            type: 'success',
            title: '¡Todo en orden!',
            text: 'La cotización ha sido ingresada al sistema.',
            time: 10000,
            group: 'notifications'
          });
        })
        .catch((error) => {
          const responseData = error.response.data;

          if ( responseData.status === 'fail' ) {
            this.emitter.emit("notification-set", {
              type: 'error',
              title: '¡Un momento!',
              text: responseData.message,
              time: 10000,
              group: 'notifications'
            });

            this.cerrarModal();

            return;
          }

          this.limpiarCampos();
          this.currentStep = 0;
          this.cerrarModal();
          this.emitter.emit("notification-set", {
              type: 'error',
              title: '¡Un momento!',
              text: 'Ha ocurrido un imprevisto. Inténtalo de nuevo.',
              time: 10000,
              group: 'notifications'
            });
        });
      },
      ingresarOT() {
        if (this.cotizacion.valorTotal >= this.datosOT.abono.montoAbono && this.datosOT.abono.montoAbono != 0) {
          this.estaInhabilitadoBotonAbono = true;
          this.error = null;
          this.datosOT.cotizacion = this.cotizacion;
          const payload = this.datosOT;
          eliminarVacios(payload);

          OTService.ingresarOrdenTrabajo(payload)
          .then((response) => {
            let otIngresada = response.data;

            this.datosVoucherOT.numeroOT = otIngresada.ot_num;
            this.datosVoucherOT.fecha = otIngresada.fechaFormateada;
            this.datosVoucherOT.abono = this.datosOT.abono.montoAbono;
            this.datosVoucherOT.saldo = otIngresada.ot_saldo;
            this.datosVoucherOT.nombre = `${otIngresada.ot_nombre} ${otIngresada.ot_apellido_pat} ${otIngresada.ot_apellido_mat}`;
            this.datosVoucherOT.telefono = otIngresada.ot_telefono;
            this.datosVoucherOT.total = otIngresada.ot_valor_total;
            this.datosVoucherOT.codigoOT = otIngresada.ot_code;
            this.datosVoucherOT.sucursal = otIngresada.nombreSucursal;
            this.datosVoucherOT.nombreVendedor = otIngresada.ot_nombre_vendedor;
            this.datosVoucherOT.fechaRetiro = moment(otIngresada.ot_fecha_retiro).format('DD/MM/YYYY');

            for (let index = 0; index < this.cotizacion.lentesCotizados.length; index++) {
              const element = this.cotizacion.lentesCotizados[index];
              let lente = {
                tipo: element.tipoLente, 
                valor: element.valorLente,
                armazon: element.armazon,
                filtro: element.tipoFiltro,
                cristal: element.tipoCristal
                };
              if (element.tipoLente == 'Multifocal') {
                lente.tipoMultifocal = element.tipoMultifocal;
              }
              this.datosVoucherOT.lentes.push(lente);
            }
            
            this.esVisibleVoucherOT = true;
          })
          .then(() => {
            this.limpiarCampos();
            this.currentStep = 0;
            this.cerrarModalConfirmacionOT();
            this.emitter.emit("notification-set", {
              type: 'success',
              title: '¡Todo en orden!',
              text: 'La OT ha sido ingresada al sistema.',
              time: 10000,
              group: 'notifications'
            });
          })
          .catch((error) => {
            const responseData = error.response.data;

            if ( responseData.status === 'fail' ) {
              this.emitter.emit("notification-set", {
                type: 'error',
                title: '¡Un momento!',
                text: responseData.message,
                time: 10000,
                group: 'notifications'
              });

              this.estaInhabilitadoBotonAbono = false;

              return;
            }

            this.limpiarCampos();
            this.currentStep = 0;
            this.cerrarModalConfirmacionOT();
            this.emitter.emit("notification-set", {
                type: 'error',
                title: '¡Un momento!',
                text: 'Ha ocurrido un imprevisto. Inténtalo de nuevo.',
                time: 10000,
                group: 'notifications'
              });
          });
        } else {
          this.emitter.emit("notification-set", {
            type: 'error',
            title: '¡Un momento!',
            text: 'No es posible realizar el abono. Verifica los datos ingresados y el saldo.',
            time: 10000,
            group: 'notifications'
          });
        }
      },
      limpiarCampos() {
        this.cotizacion.observacion = '';
        this.cotizacion.valorTotal = 0;
        this.cotizacion.nombre = '';
        this.cotizacion.apellidoPaterno = '';
        this.cotizacion.apellidoMaterno = '';
        this.cotizacion.telefono = '';
        this.cotizacion.laboratorio = '';
        this.cotizacion.nombreVendedor = '';
        this.cotizacion.fechaIngreso = '';
        this.cotizacion.numeroCotz = '';

        this.lenteLejos.esfericoIzquierdo = '';
        this.lenteLejos.esfericoDerecho = '';
        this.lenteLejos.cilindricoIzquierdo = '';
        this.lenteLejos.cilindricoDerecho = '';
        this.lenteLejos.gradosCilindricoIzquierdo = '';
        this.lenteLejos.gradosCilindricoDerecho = '';
        this.lenteLejos.armazon = '';
        this.lenteLejos.tipoFiltro = '';
        this.lenteLejos.tipoCristal = '';
        this.lenteLejos.valorLente = 0;
        this.lenteLejos.dp = '';

        this.lenteCerca.esfericoIzquierdo = '';
        this.lenteCerca.esfericoDerecho = '';
        this.lenteCerca.cilindricoIzquierdo = '';
        this.lenteCerca.cilindricoDerecho = '';
        this.lenteCerca.gradosCilindricoIzquierdo = '';
        this.lenteCerca.gradosCilindricoDerecho = '';
        this.lenteCerca.armazon = '';
        this.lenteCerca.tipoFiltro = '';
        this.lenteCerca.tipoCristal = '';
        this.lenteCerca.valorLente = 0;
        this.lenteCerca.dp = '';

        this.lenteMultifocal.lejos.esfericoIzquierdo = '';
        this.lenteMultifocal.lejos.esfericoDerecho = '';
        this.lenteMultifocal.lejos.cilindricoIzquierdo = '';
        this.lenteMultifocal.lejos.cilindricoDerecho = '';
        this.lenteMultifocal.lejos.gradosCilindricoIzquierdo = '';
        this.lenteMultifocal.lejos.gradosCilindricoDerecho = '';
        this.lenteMultifocal.cerca.esfericoIzquierdo = '';
        this.lenteMultifocal.cerca.esfericoDerecho = '';
        this.lenteMultifocal.cerca.cilindricoIzquierdo = '';
        this.lenteMultifocal.cerca.cilindricoDerecho = '';
        this.lenteMultifocal.cerca.gradosCilindricoIzquierdo = '';
        this.lenteMultifocal.cerca.gradosCilindricoDerecho = '';
        this.lenteMultifocal.armazon = '';
        this.lenteMultifocal.tipoFiltro = '';
        this.lenteMultifocal.tipoCristal = '';
        this.lenteMultifocal.dp = '';
        this.lenteMultifocal.tipoMultifocal = '';
        this.lenteMultifocal.valorLente = 0;

        this.lenteBifocal.lejos.esfericoIzquierdo = '';
        this.lenteBifocal.lejos.esfericoDerecho = '';
        this.lenteBifocal.lejos.cilindricoIzquierdo = '';
        this.lenteBifocal.lejos.cilindricoDerecho = '';
        this.lenteBifocal.lejos.gradosCilindricoIzquierdo = '';
        this.lenteBifocal.lejos.gradosCilindricoDerecho = '';
        this.lenteBifocal.cerca.esfericoIzquierdo = '';
        this.lenteBifocal.cerca.esfericoDerecho = '';
        this.lenteBifocal.cerca.cilindricoIzquierdo = '';
        this.lenteBifocal.cerca.cilindricoDerecho = '';
        this.lenteBifocal.cerca.gradosCilindricoIzquierdo = '';
        this.lenteBifocal.cerca.gradosCilindricoDerecho = '';
        this.lenteBifocal.armazon = '';
        this.lenteBifocal.tipoFiltro = '';
        this.lenteBifocal.tipoCristal = '';
        this.lenteBifocal.dp = '';
        this.lenteBifocal.valorLente = 0;

        this.cotizacion.lentesCotizados = [];

        this.cilindricoLejosDerechoSeleccionado = false;
        this.cilindricoLejosIzquierdoSeleccionado = false;
        this.cilindricoCercaDerechoSeleccionado = false;
        this.cilindricoCercaIzquierdoSeleccionado = false;
        this.cilindricoMultifocalDerechoLejosSeleccionado = false;
        this.cilindricoMultifocalIzquierdoLejosSeleccionado = false;
        this.cilindricoMultifocalDerechoCercaSeleccionado = false;
        this.cilindricoMultifocalIzquierdoCercaSeleccionado = false;
        this.cilindricoBifocalDerechoLejosSeleccionado = false;
        this.cilindricoBifocalIzquierdoLejosSeleccionado = false;
        this.cilindricoBifocalDerechoCercaSeleccionado = false;
        this.cilindricoBifocalIzquierdoCercaSeleccionado = false;

        this.lejosSeleccionado = false;
        this.cercaSeleccionado = false;
        this.multifocalSeleccionado = false;
        this.bifocalSeleccionado = false;

        this.limpiarCamposAbono();
      },
      mostrarValorTotal() {
        let valorLejos = 0;
        let valorCerca = 0;
        let valorMultifocal = 0;
        let valorBifocal = 0;

        if (this.lenteLejos.valorLente != '') {
          valorLejos = parseInt(this.lenteLejos.valorLente);
        }
        if (this.lenteCerca.valorLente != '') {
          valorCerca = parseInt(this.lenteCerca.valorLente);
        }
        if (this.lenteMultifocal.valorLente != '') {
          valorMultifocal = parseInt(this.lenteMultifocal.valorLente);
        }
        if (this.lenteBifocal.valorLente != '') {
          valorBifocal = parseInt(this.lenteBifocal.valorLente);
        }

        let total =  valorLejos + valorCerca + valorMultifocal + valorBifocal;
        this.cotizacion.valorTotal = total;

        this.valorTotalFormateado = `$ ${this.formatearMonto(total)}`;
      },
      formatearMonto(valor) {
        return separarPorMiles(valor);
      },
      cerrarModal() {
        this.esVisibleModal = false;
        this.estaInhabilitadoBotonCotizar = false;
      },
      abrirModal() {
        this.esVisibleModal = true;
      },
      confirmarCotizacion() {
        this.abrirModal();
      },
      abrirModalConfirmacionOT() {
        this.modalConfirmacionOT = true;
      },
      cerrarModalConfirmacionOT() {
        this.modalConfirmacionOT = false;
        this.estaInhabilitadoBotonAbono = false;
        this.limpiarCamposAbono();
      },
      limpiarCamposAbono() {
        this.datosOT.abono.medioPagoAbono = 'Efectivo';
        this.datosOT.abono.montoAbono = 0;
        this.datosOT.credito = false;
        this.datosOT.numeroBoleta = '';
        this.datosOT.fechaRetiro = moment(new Date()).format('DD-MM-YYYY');
      },
      imprimirVoucher(idElemento) {
        printSection(idElemento);
      },
      limpiarCamposVoucherOT() {
        this.datosVoucherOT.numeroOT = 0;
        this.datosVoucherOT.fecha = '00/00/0000';
        this.datosVoucherOT.nombre = '';
        this.datosVoucherOT.telefono = '';
        this.datosVoucherOT.lentes = [];
        this.datosVoucherOT.abono = 0;
        this.datosVoucherOT.saldo = 0;
        this.datosVoucherOT.total = 0;
      },
      limpiarCamposVoucherCotz() {
        this.datosVoucherCotz.numeroCotz = 0;
        this.datosVoucherCotz.fecha = '00/00/0000';
        this.datosVoucherCotz.nombre = '';
        this.datosVoucherCotz.lentes = [];
        this.datosVoucherCotz.total = 0;
      },
      cerrarModalVoucherOT() {
        this.esVisibleVoucherOT = false;
        this.limpiarCamposVoucherOT();
      },
      cerrarModalVoucherCotz() {
        this.esVisibleVoucherCotz = false;
        this.limpiarCamposVoucherCotz();
      }
    },
    computed: {
        setValorLenteLejos: {
            get: function() {
              return `$ ${this.lenteLejos.valorLente.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}`  
            },
            set: function(modifiedValue) {
                
                let newValue = parseFloat(modifiedValue.replace(/[$.]/g, ""));
                
                if (isNaN(newValue)) {
                    newValue = 0;
                }
                
                this.lenteLejos.valorLente = newValue;
            }
        },
        setValorLenteCerca: {
            get: function() { 
              return `$ ${this.lenteCerca.valorLente.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}`; 
            },
            set: function(modifiedValue) {
                let newValue = parseFloat(modifiedValue.replace(/[$.]/g, ""));
                
                if (isNaN(newValue)) {
                    newValue = 0;
                }
                
                this.lenteCerca.valorLente = newValue;
            }
        },
        setValorLenteMultifocal: {
            get: function() {
              return `$ ${this.lenteMultifocal.valorLente.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}`;
            },
            set: function(modifiedValue) {
                let newValue = parseFloat(modifiedValue.replace(/[$.]/g, ""));
                
                if (isNaN(newValue)) {
                    newValue = 0;
                }
                
                this.lenteMultifocal.valorLente = newValue;
            }
        },
        setValorLenteBifocal: {
            get: function() { 
              return `$ ${this.lenteBifocal.valorLente.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}`;   
            },
            set: function(modifiedValue) {
                
                let newValue = parseFloat(modifiedValue.replace(/[$.]/g, ""));
                
                if (isNaN(newValue)) {
                    newValue = 0;
                }
                
                this.lenteBifocal.valorLente = newValue;
            }
        },
        setMontoAbono: {
          get: function() {
            return `$ ${this.datosOT.abono.montoAbono.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}`;
          },
          set: function(modifiedValue) {          
            let newValue = parseFloat(modifiedValue.replace(/[$.]/g, ""))        
            if (isNaN(newValue)) {
                newValue = 0;
            }        
            this.datosOT.abono.montoAbono = newValue;
          }
        }
    },
    components: {
    Field,
    Form,
    ErrorMessage,
    Modal,
    Voucher,
    VoucherCotizacion,
    VoucherOT,
    DatePicker
  }
};
</script>