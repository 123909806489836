<template>
    <div class="bg-blue-50 px-6 z-40">
        <FormCotizacion/>
    </div>
</template>

<script>
import FormCotizacion from "@/components/cotizaciones/FormCotizacion"

export default {
    name: "NuevaCotizacion",
    components: {
        FormCotizacion,
    }
}
</script>