<template>
    <div class="mt-2">
        <div>
            <h2 class="text-xl font-semibold text-center">Orden de trabajo</h2>
        </div>

        <div>
            <span v-html="this.codigoBarras" class="flex justify-center w-full"></span>
        </div>
        
        <div class="grid grid-cols-3 text-sm my-2">
            <div class="text-left">
                Nº OT:
            </div>
            <div class="text-right col-span-2">
                {{ datosOT.numeroOT }}
            </div>
            <div class="text-left">
                Fecha:
            </div>
            <div class="text-right col-span-2">
                {{ datosOT.fecha }}
            </div>
            <div class="text-left">
                Local:
            </div>
            <div class="text-right col-span-2">
                {{ datosOT.sucursal }}
            </div>
            <div class="text-left">
                Entrega:
            </div>
            <div class="text-right col-span-2">
                {{ datosOT.fechaRetiro }}
            </div>
            <div class="text-left">
                Cliente:
            </div>
            <div class="text-right col-span-2">
                {{ datosOT.nombre }}
            </div>
            <div class="text-left">
                Asesor:
            </div>
            <div class="text-right col-span-2">
                {{ datosOT.nombreVendedor }}
            </div>
            <div class="text-left">
                Teléfono:
            </div>
            <div class="text-right col-span-2">
                {{ datosOT.telefono }}
            </div>
        </div>
        <div class="grid grid-cols-2 my-2">
            <div class="text-left col-span-2 text-base">
                Detalle lentes
            </div>
            <template v-for="(lente,indice) in datosOT.lentes" :key="indice">
                <div class="text-left text-lg col-span-2 font-semibold">
                    {{ lente.tipo }}
                </div>
                <div class="text-left text-sm">
                    Armazón:
                </div>
                <div class="text-right text-sm">
                    {{ lente.armazon }}
                </div>
                <div class="text-left text-sm">
                    Cristal:
                </div>
                <div class="text-right text-sm">
                    {{ lente.cristal }}
                </div>
                <div class="text-left text-sm">
                    Filtro:
                </div>
                <div class="text-right text-sm">
                    {{ lente.filtro }}
                </div>
                <div 
                class="text-left text-sm"
                v-if="lente.tipo == 'Multifocal'"
                >
                    T.multifocal:
                </div>
                <div 
                class="text-right text-sm"
                v-if="lente.tipo == 'Multifocal'"
                >
                    {{ lente.tipoMultifocal }}
                </div>
                <div class="text-left text-sm">
                    Precio:
                </div>
                <div class="text-right text-sm">
                    {{ this.formatearCifra(lente.valor) }}
                </div>
            </template>
            <div class="text-left text-xl font-semibold uppercase">
                Total:
            </div>
            <div class="text-right text-xl font-semibold">
                {{ `$ ${this.formatearCifra(datosOT.total)}` }}
            </div>
        </div>
        <div class="grid grid-cols-2 mb-2 mt-4">
            <div class="text-left text-sm">
                Monto abono:
            </div>
            <div class="text-right text-sm">
                {{ `$ ${this.formatearCifra(datosOT.abono)}` }}
            </div>
            <div class="text-left text-xl font-semibold uppercase">
                Saldo:
            </div>
            <div class="text-right text-xl font-semibold">
                {{ `$ ${this.formatearCifra(datosOT.saldo)}` }}
            </div>
        </div>
    </div>
</template>

<script>
import { separarPorMiles } from "@/utils/helpers";

import OTService from "@/services/OrdenTrabajoService";

export default {
    data() {
        return {
            codigoBarras: ''
        }
    },
    methods: {
        formatearCifra(cifra) {
            return separarPorMiles(cifra);
        },
        obtenerCodigoBarras(numeroOT) {
            OTService.obtenerCodigoBarras(numeroOT.toString()).
            then((response) => {
                this.codigoBarras = response.data;
            });
        }
    },
    props: {
        datosOT: {
            type: Object,
            default: null
        }
    },
    mounted() {
        this.obtenerCodigoBarras(this.datosOT.numeroOT);
    }
}
</script>